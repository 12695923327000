import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import moment from "moment";
import "./Homepage.styles.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PopupPage from "../popup-deals/Popuppage";
import axios from "axios";
import { theme_airline_url } from "../../utils/apiInfo";
import Delta from "../../assets/images/new/delta.png"
import Alaska from "../../assets/images/new/alaska.png"
import United from "../../assets/images/new/united.png"
import Jetblue from "../../assets/images/new/jetblue.png"
import Volaris from "../../assets/images/new/volaris.png"
import Southwest from "../../assets/images/new/southwest.png"
import Person1 from "../../assets/images/new/person1.jpg"
import Person2 from "../../assets/images/new/person2.jpg"
import Person3 from "../../assets/images/new/person3.jpg"
import Person4 from "../../assets/images/new/person4.jpg"
import BestTour from "../../components/BestTours/BestTour";
import BestTourAu from "../../components/BestTours/BestTourAu";
import BestTourCa from "../../components/BestTours/BestTourCa";
import BestTourIn from "../../components/BestTours/BestTourIn";
import BestTourNz from "../../components/BestTours/BestTourNz";
import BestTourPh from "../../components/BestTours/BestTourPh";
import BestTourQa from "../../components/BestTours/BestTourQa";
import BestTourRp from "../../components/BestTours/BestTourRp";
import BestTourSg from "../../components/BestTours/BestTourSg";
import BestTourUAE from "../../components/BestTours/BestTourUAE";
import BestTourHk from "../../components/BestTours/BestTourHk";
import BestTourMy from "../../components/BestTours/BestTourMy";
import BestTourZa from "../../components/BestTours/BestTourZa";
import BestTourTh from "../../components/BestTours/BestTourTh";
import BestTourUk from "../../components/BestTours/BestTourUk";
import BestTourVn from "../../components/BestTours/BestTourVn";


const HomePage = () => {
  const navigate = useNavigate();
  const [res, setRes] = useState();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };


  const loadBestTour = () => {
    let urlname
    urlname = window.location.hostname.split('.')[0];
    if (urlname === 'localhost') {
      urlname = "en"
    } else {
      if (urlname === 'www') {
        urlname = "en"
      }
    }

    return (
      <>
        {urlname === 'tripnair' ? <BestTour /> : ""}
        {urlname === 'au' ? <BestTourAu /> : ""}
        {urlname === 'ca' ? <BestTourCa /> : ""}
        {urlname === 'hk' ? <BestTourHk /> : ""}
        {urlname === 'in' ? <BestTourIn /> : ""}
        {urlname === 'id' ? <BestTourRp /> : ""}
        {urlname === 'my' ? <BestTourMy /> : ""}
        {urlname === 'nz' ? <BestTourNz /> : ""}
        {urlname === 'ph' ? <BestTourPh /> : ""}
        {urlname === 'qa' ? <BestTourQa /> : ""}
        {urlname === 'sg' ? <BestTourSg /> : ""}
        {urlname === 'sa' ? <BestTourZa /> : ""}
        {urlname === 'th' ? <BestTourTh /> : ""}
        {urlname === 'en' ? <BestTour /> : ""}
        {urlname === 'es' ? <BestTour /> : ""}
        {urlname === 'ae' ? <BestTourUAE /> : ""}
        {urlname === 'uk' ? <BestTourUk /> : ""}
        {urlname === 'vn' ? <BestTourVn /> : ""}
      </>
    )
  }
  // const [showPopup, setPopup] = useState('active')

  // function removePopup(){
  //   setPopup('')
  // }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  const urlFull = window.location.href;
  useEffect(() => {
    axios
      .get(`${theme_airline_url}`, {
        params: {
          url: urlFull,
        },
      })
      .then((response) => {
        setRes(response.data.status);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, [urlFull]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Tripnair | Home </title>
      </Helmet>
      {res === "1" ? <PopupPage /> : ""}


      <section className="gap">
        <div className="container flex space-bw align-center">
          <div className="hero-text col-50">
            <h5>Travel around the world</h5>
            <h1><span>Explore Destinations Effortlessly</span><br /> With Our User-friendly Interface.</h1>
            <p>Find and book flights in just a few clicks, making travel planning a breeze.</p>
          </div>
          <div className="col-50">
            <div className="booking-form">
              <FlightSearchForm />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="common-text">
            <h5>Popular Airlines</h5>
            <h2>The most well-known airlines</h2>
          </div>
          <div className="pop-airlines flex">
            <div className="airlines-area">
              <img src={Delta} alt="" />
            </div>
            <div className="airlines-area">
              <img src={Alaska} alt="" />
            </div>
            <div className="airlines-area">
              <img src={United} alt="" />
            </div>
            <div className="airlines-area">
              <img src={Jetblue} alt="" />
            </div>
            <div className="airlines-area">
              <img src={Volaris} alt="" />
            </div>
            <div className="airlines-area">
              <img src={Southwest} alt="" />
            </div>
          </div>
        </div>
      </section>

      {loadBestTour()}

      <section className="gap">
        <div className="container">
          <div className="common-text">
            <h5>Our Testimonials</h5>
            <h2>Look What Travellers Say About Us</h2>
          </div>
          <Slider {...settings} className="testimonials-slider">
            <div className="testimonials-slides">
              <p>Navigating flights for the first time was stress-free. Simple interface, clear instructions, and reassuring updates. Tripnair.com made my debut travel experience smooth and enjoyable</p>
              <img src={Person1} alt="" />
              <h3>Emily Johnson</h3>
            </div>
            <div className="testimonials-slides">
              <p>Last-minute flight needed for a crucial meeting. Found a great deal here, a swift booking process, and on-time updates. Tripnair.com helped save the day professionally</p>
              <img src={Person2} alt="" />
              <h3>Jacob Smith</h3>
            </div>
            <div className="testimonials-slides">
              <p>Tripnair.com is my pocket guide for booking flights whenever I travel. I love their services and 24/7 customer support. Lots of great, helpful information! Keep up the great work!</p>
              <img src={Person3} alt="" />
              <h3>Olivia Anderson</h3>
            </div>
            <div className="testimonials-slides">
              <p>I've used Tripnair.com numerous times with wonderful results. I've booked on other sites before and have had major issues with their customer support, but they are the best in terms of customer satisfaction</p>
              <img src={Person4} alt="" />
              <h3>Michael Williams</h3>
            </div>
          </Slider>
        </div>
      </section>

    </React.Fragment >
  );
};

export default HomePage;
