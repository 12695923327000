import React from "react";
import { Link } from "react-router-dom";
import CallGif from "../../assets/images/new/call-image.gif"
import CountrySelector from "../countrySeloctor/CountrySelector"
import allcard from "../../assets/images/flight/card-home.webp"

import "./Footer.styles.css";

const Footer = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer>
      <div className="foot-top-ar container flex space-bw">
        <div className="col-50 flex space-bw">
          <div className="col-25">
            <h3>Contact Us</h3>
            <ul>
              {/* <li>
                <p>Customer Care</p>
                <a href="tel:+18887380865">+1-888-738-0865</a>
              </li> */}
              <li>
                <p>Need Live Support</p>
                <a href="mailto:info@Tripnair.com">info@Tripnair.com</a>
              </li>
              <li>
                <CountrySelector />
              </li>
              {/* <li>
              <p>Locate Us</p>
              <span>Trian Incorporation</span><br />  
              <Link to="">47 Eliot St <br /> Natick, MA 01760, USA</Link>
            </li> */}
            </ul>
          </div>
          <div className="col-25">
            <h3>Our Company</h3>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-50 flex space-bw">
          <div className="col-25">
            <h3>Airlines</h3>
            <ul>
              <li>
                <Link to="/united-airlines">United Airlines</Link>
              </li>
              <li>
                <Link to="/delta-airlines">Delta Airlines</Link>
              </li>
            </ul>
          </div>
          <div className="col-25">
            <h3>Support</h3>
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
              <li>
                <Link to="/refund-and-cancellation-policy">Refund and Cancellation Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="disclaimer-area">
          <p><b>Disclaimer:</b> Tripnair.com is a self-reliant online travel agency and not associated with any third party. By accessing and using Tripnair.com website you agree that Tripnair.com is not liable for any direct or indirect loss, however arising, from the use of any of the information, offers, materials or links to other sites found on this website. If you have any queries you simply email us at <a href="mailto:info@tripnair.com">info@tripnair.com</a></p>
          <div className="card-accept cm-txt-center">
            <img src={allcard} alt="" />
          </div>
        </div>
        <div className="rights cm-txt-center">
          <p>Copyrights © 2024 Tripnair All Rights Reserved.</p>
        </div>
      </div>
      {/* <div className="foot-bottom flex align-center">
        <a href="tel:+1-888-738-0865">
          <img src={CallGif} alt="" />
          +1-888-738-0865
        </a>
      </div> */}
    </footer>
  );
};

export default Footer;
